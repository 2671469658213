import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import FloatingVue from 'floating-vue'
import ElSelectAll from 'el-select-all'
import ElementUI from 'element-ui'

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as echarts from 'echarts'
import router from './router'
import store from './store'
import App from './App.vue'

import kmb from './utils/kmb.js'



import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/dark-layout.scss'
import '@/assets/scss/light.scss'
import '@/assets/scss/element-ui-reset.scss'
import 'floating-vue/dist/style.css'
import './global-components'
import '@/libs/portal-vue'
import '@/libs/toastification'
import './icons/icon'

import 'echarts-gl';

import DateTimeFormat from './utils/datetime-format';
import { formatDate, dateTypeFormat, setNumberColor, formatNum, Decimals, getCurrentDate, weekDay } from './utils/utils.js'

Vue.prototype.kmb = kmb;
Vue.use(ElSelectAll)
Vue.use(DateTimeFormat);
Vue.use(FloatingVue)
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)
Vue.use(VueAxios, axios)
Vue.prototype.formatDate = formatDate;
Vue.prototype.dateTypeFormat = dateTypeFormat;
Vue.prototype.setNumberColor = setNumberColor;
Vue.prototype.formatNum = formatNum;
Vue.prototype.Decimals = Decimals;
Vue.prototype.getCurrentDate = getCurrentDate;
Vue.prototype.weekDay = weekDay;
Vue.prototype.$echarts = echarts
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCompositionAPI)
Vue.use(ElementUI)

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
