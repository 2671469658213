export default function ssss(value) {
    value = parseFloat(value);
    const absValue = Math.abs(value); // 取绝对值
    let result = "";

    if (absValue >= 1000000000) {
        result = (absValue / 1000000000).toFixed(2) + "B";
    } else if (absValue >= 1000000) {
        result = (absValue / 1000000).toFixed(2) + "M";
    } else if (absValue >= 1000) {
        result = (absValue / 1000).toFixed(2) + "K";
    } else {
        result = absValue.toFixed(2);
    }

    if (value < 0) {
        result = "-" + result;
    }

    return result;
}




