var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header navbar-logo-header expanded"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.appLogo,"alt":"logo"}})],1),_c('h2',{staticClass:"brand-text"},[_vm._v(_vm._s(_vm.appName))])])],1),_c('li',{staticClass:"nav-item nav-toggle",staticStyle:{"display":"none"}},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1)],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('el-menu',{staticClass:"my-menu",attrs:{"default-active":this.$route.path,"background-color":"transparent","text-color":"rgba(209, 212, 220, 0.8)","active-text-color":"#000000","default-openeds":_vm.openedSubmenus,"router":""},on:{"open":_vm.handleOpen,"close":_vm.handleClose}},[_c('el-menu-item',{class:{ active: _vm.$route.path.startsWith('/home') },attrs:{"index":"/home"}},[_c('i',{style:({
          color: _vm.$route.path.startsWith('/home')
            ? 'rgba(237, 189, 136, 1)'
            : '',
        })},[_c('img',{attrs:{"src":_vm.$route.path.startsWith('/home')
              ? require('@/assets/images/menu/home-y.svg')
              : require('@/assets/images/menu/home.svg'),"alt":"Home Icon"}})]),_c('span',{style:({
          color: _vm.$route.path.startsWith('/home')
            ? 'rgba(237, 189, 136, 1)'
            : '',
        }),attrs:{"slot":"title"},slot:"title"},[_vm._v("首页")])]),_c('el-submenu',{attrs:{"index":"option"}},[_c('template',{slot:"title"},[_c('i',{style:({
            color:
              _vm.$route.path.startsWith('/positionData') ||
              _vm.$route.path.startsWith('/compositeNumber') ||
              _vm.$route.path.startsWith('/waveRate') ||
              _vm.$route.path.startsWith('/otherNumbers') ||
              _vm.$route.path.startsWith('/bybitCompositeNumber') ||
              _vm.$route.path.startsWith('/bybitWaveRate') ||
              _vm.$route.path.startsWith('/bybitOtherNumbers')
                ? 'rgba(237, 189, 136, 1)'
                : '',
          })},[_c('img',{attrs:{"src":_vm.$route.path.startsWith('/positionData') ||
              _vm.$route.path.startsWith('/compositeNumber') ||
              _vm.$route.path.startsWith('/waveRate') ||
              _vm.$route.path.startsWith('/otherNumbers') ||
              _vm.$route.path.startsWith('/bybitCompositeNumber') ||
              _vm.$route.path.startsWith('/bybitWaveRate') ||
              _vm.$route.path.startsWith('/bybitOtherNumbers')
                ? require('@/assets/images/menu/option-y.svg')
                : require('@/assets/images/menu/option.svg'),"alt":"Option Icon"}})]),_c('span',{style:({
            color:
              _vm.$route.path.startsWith('/positionData') ||
              _vm.$route.path.startsWith('/compositeNumber') ||
              _vm.$route.path.startsWith('/waveRate') ||
              _vm.$route.path.startsWith('/otherNumbers') ||
              _vm.$route.path.startsWith('/bybitCompositeNumber') ||
              _vm.$route.path.startsWith('/bybitWaveRate') ||
              _vm.$route.path.startsWith('/bybitOtherNumbers')
                ? 'rgba(237, 189, 136, 1)'
                : '',
          })},[_vm._v("期权")])]),_c('el-submenu',{attrs:{"index":"option-deribit"}},[_c('template',{slot:"title"},[_c('span',{style:({
              color:
                _vm.$route.path.startsWith('/positionData') ||
                _vm.$route.path.startsWith('/compositeNumber') ||
                _vm.$route.path.startsWith('/waveRate') ||
                _vm.$route.path.startsWith('/otherNumbers')
                  ? 'rgba(237, 189, 136, 1)'
                  : '',
            })},[_vm._v("Deribit")])]),_c('el-menu-item',{attrs:{"index":"/positionData"}},[_vm._v("持仓数据")]),_c('el-menu-item',{attrs:{"index":"/compositeNumber"}},[_vm._v("成交数据")]),_c('el-menu-item',{attrs:{"index":"/waveRate"}},[_vm._v("波动率")]),_c('el-menu-item',{attrs:{"index":"/otherNumbers"}},[_vm._v("其他数据")])],2),_c('el-submenu',{attrs:{"index":"option-bybit"}},[_c('template',{slot:"title"},[_c('span',{style:({
              color:
                _vm.$route.path.startsWith('/bybitCompositeNumber') ||
                _vm.$route.path.startsWith('/bybitWaveRate') ||
                _vm.$route.path.startsWith('/bybitOtherNumbers')
                  ? 'rgba(237, 189, 136, 1)'
                  : '',
            })},[_vm._v("Bybit")])]),_c('el-menu-item',{attrs:{"index":"/bybitCompositeNumber"}},[_vm._v("成交数据")]),_c('el-menu-item',{attrs:{"index":"/bybitWaveRate"}},[_vm._v("波动率")]),_c('el-menu-item',{attrs:{"index":"/bybitOtherNumbers"}},[_vm._v("其他数据")])],2)],2),_c('el-submenu',{attrs:{"index":"block-transactions"}},[_c('template',{slot:"title"},[_c('i',{style:({
            color:
              _vm.$route.path.startsWith('/DeribitBlockTransactions') ||
              _vm.$route.path.startsWith('/BybitBlockTransactions')
                ? 'rgba(237, 189, 136, 1)'
                : '',
          })},[_c('img',{attrs:{"src":_vm.$route.path.startsWith('/DeribitBlockTransactions') ||
              _vm.$route.path.startsWith('/BybitBlockTransactions')
                ? require('@/assets/images/menu/block-y.svg')
                : require('@/assets/images/menu/block.svg'),"alt":"Block Icon"}})]),_c('span',{style:({
            color:
              _vm.$route.path.startsWith('/DeribitBlockTransactions') ||
              _vm.$route.path.startsWith('/BybitBlockTransactions')
                ? 'rgba(237, 189, 136, 1)'
                : '',
          })},[_vm._v("大宗交易")])]),_c('el-menu-item',{attrs:{"index":"/DeribitBlockTransactions"}},[_vm._v("Deribit期权")]),_c('el-menu-item',{attrs:{"index":"/BybitBlockTransactions"}},[_vm._v("Bybit期权")])],2),_c('el-menu-item',{class:{ active: _vm.$route.path.startsWith('/btcEtf') },attrs:{"index":"/btcEtf"}},[_c('i',{style:({
          color: _vm.$route.path.startsWith('/btcEtf')
            ? 'rgba(237, 189, 136, 1)'
            : '',
        })},[_c('img',{attrs:{"src":_vm.$route.path.startsWith('/btcEtf')
              ? require('@/assets/images/menu/btc-etf-y.svg')
              : require('@/assets/images/menu/btc-etf.svg'),"alt":"Home Icon"}})]),_c('span',{style:({
          color: _vm.$route.path.startsWith('/btcEtf')
            ? 'rgba(237, 189, 136, 1)'
            : '',
        }),attrs:{"slot":"title"},slot:"title"},[_vm._v("BTC-ETF")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }