// datetime-format.js
const DateTimeFormat = {
    install(Vue) {
        // 将格式化方法添加到Vue的原型上，以便全局使用
        Vue.prototype.$formatDateTime = function (isoString) {
            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        };
    },
};

export default DateTimeFormat;
