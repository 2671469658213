
export default [
  // 加一个路由 就得在这个里面配置一下
  {
    title: '首页',
    route: 'home',
    icon: 'HomeIcon',
  },
  // {
  //   title: '资金费率',
  //   route: 'FundingRate',
  //   icon: 'DollarSignIcon',
  // },
  // {
  //   title: '多空比',
  //   icon: 'PercentIcon',
  //   route: 'duokongbi',
  //   children: [
  //     {
  //       title: 'Binance',
  //       // icon: 'FileIcon',
  //       children: [
  //         {
  //           title: '大户账户数多空比',
  //           route: 'Accounts',
  //           // icon: 'TagIcon',
  //         },
  //         {
  //           title: '大户持仓量多空比',
  //           route: 'Positions',
  //           // icon: 'FileIcon',
  //         },
  //         {
  //           title: '账户数多空比',
  //           route: 'LongShortRatio',
  //           // icon: 'FileIcon',
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Bybit',
  //       // icon: 'FileIcon',
  //       children: [
  //         {
  //           title: '账户数多空比',
  //           route: 'BybitLongShortRatio',
  //           icon: 'FileIcon',
  //         },
  //       ]
  //     },
  //   ],
  // },
  //隐藏的期权
  {
    title: '期权',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Deribit',
        children: [
          {
            title: '持仓数据',
            route: 'positionData',
          },
          {
            title: '成交数据',
            route: 'compositeNumber',
          },
          {
            title: '波动率',
            route: 'waveRate',
          },
          {
            title: '其他数据',
            route: 'otherNumbers',
          },
        ]
      }, {
        title: 'Bybit',
        children: [
          {
            "title": "成交数据",
            "route": "bybitCompositeNumber"
          },
          {
            "title": "波动率",
            "route": "bybitWaveRate"
          },
          {
            "title": "其他数据",
            "route": "bybitOtherNumbers"
          }
        ]
      }
    ]
  },
  // {
  //   title: '期权',
  //   route: 'qiquan',
  //   icon: 'PieChartIcon',
  //   children: [

  //     {
  //       title: 'Deribit',
  //       route: 'deribitOption',
  //     },
  //     {
  //       title: 'Bybit',
  //       route: 'bybitOption',
  //     },

  //     // {
  //     //   title: 'Deribit期权',
  //     //   route: 'options',
  //     //   // icon: 'FileIcon',
  //     // },
  //     // {
  //     //   title: 'Deribit期权标的',
  //     //   route: 'deribitOptionUnderlying',
  //     // },
  //     // {
  //     //   title: 'Deribit期权持仓量',
  //     //   route: 'deribitOptionsPosition',
  //     //   // icon: 'FileIcon',
  //     // },
  //   ],
  // },
  // {
  //   title: 'BTC-ETF',
  //   icon: 'BoldIcon',
  //   children: [
  //     {
  //       title: '总览',
  //       // icon: 'FileIcon',
  //       route: 'AllBrowse',
  //     },
  //     {
  //       title: 'BITO',
  //       // icon: 'FileIcon',
  //       route: 'Bito',
  //     },
  //     {
  //       title: 'BTF',
  //       // icon: 'FileIcon',
  //       route: 'Btf',
  //     },
  //     {
  //       title: 'Purpose',
  //       // icon: 'FileIcon',
  //       route: 'Purpose',
  //     },
  //   ],
  // },
  {
    title: '大宗交易',
    icon: 'SlackIcon',
    children: [
      {
        title: 'Deribit期权',
        // icon: 'FileIcon',
        route: 'DeribitBlockTransactions',
      },
      // {
      //   title: '1111',
      //   // icon: 'FileIcon',
      //   route: 'inHistoricalData',
      // },
      {
        title: 'Bybit期权',
        // icon: 'FileIcon',
        route: 'BybitBlockTransactions',
      },
    ],
  },
  {
    title: 'K线',
    icon: 'SlackIcon',
    children: [
      {
        title: 'K线',
        // icon: 'FileIcon',
        route: 'TVChartContainer',
      },
    ],
  },
]
