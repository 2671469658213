<template>
  <div class="navbar-container cccccc d-flex content align-items-center">
    <!-- <div class="navbar-collapse">
      <dl>
        <dt>
          <a href="https://discord.com/invite/tvGPf6AdVS">Discord</a>
        </dt>
        <dt>
          <a href="https://t.me/rtaacademy">Telegram</a>
        </dt>
        <dt>
          <a href="https://youtube.com">Youtube</a>
        </dt>
        <dt>
          <a href="https://rta.academy/">学院官网</a>
        </dt>
      </dl>
    </div> -->
    <div class="Community">
      <a target="_blank" href="https://discord.gg/rta">
        <img src="@/assets/images/rtaLogo/discord.svg" />
      </a>
      <a target="_blank" href="https://www.youtube.com/@RTAAcademy">
        <img src="@/assets/images/rtaLogo/youtube.svg" alt=""
      /></a>
      <a target="_blank" href="https://twitter.com/RtaAcademy">
        <img src="@/assets/images/rtaLogo/twitter.svg" alt=""
      /></a>
      <a
        class="tradingview"
        target="_blank"
        href="https://cn.tradingview.com/u/RTA-Academy/"
      >
        <img src="@/assets/images/rtaLogo/tradingview.svg" alt="" />
      </a>
      <a target="_blank" href="https://t.me/rtachannels"
        ><img src="@/assets/images/rtaLogo/telegram.svg" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style lang="scss">
[dir]
  .header-navbar
  .navbar-container
  ul.navbar-nav
  li.dropdown-user
  .dropdown-menu {
  margin-top: 10px;
  display: none !important;
}
.navbar-container {
  padding: 0px !important;
  // .navbar-collapse {
  //   justify-content: flex-end;
  //   dl {
  //     display: flex;
  //     margin: 0;
  //   }
  //   dt {
  //     font-weight: 600;
  //     margin: 0 15px 0 15px;
  //     a {
  //       font-family: Roboto-Regular, Roboto;
  //       font-weight: 400;
  //       color: #efc394;
  //     }
  //   }
  // }
  .Community {
    width: 100%;
    height: 100%;
    text-align: right;
    padding: 0 32px !important;
    a {
      width: 20px;
      height: 20px;
      margin-left: 32px;
      overflow: hidden;
      text-decoration: none;
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 0;
      }
    }
    a:hover img {
      top: -150px;
      filter: drop-shadow(0px 150px 0px #efc394);
    }
  }
}
</style>