<template>
  <b-nav-item @click="() => {
      skin = isDark ? 'light' : 'dark';
      getIsDark(skin,isDark)
    }">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  methods:  {
    getIsDark(skin,isDark) {
      localStorage.setItem('isDark',isDark);
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')
    // console.log('aaaaa',isDark)
    return { skin, isDark }
  },
}
</script>
