
/*
 * 验证字符串是否为空（也不能为纯空格）
 * true--说明为空， false--说明不为空
 */
export function isEmptyString(string) {
    if (
        string == undefined ||
        typeof string == 'undefined' ||
        !string ||
        string == null ||
        string == '' ||
        /^\s+$/gi.test(string)
    ) {
        return true;
    } else {
        return false;
    }
}

/**
 * 判断数据类型
 * @param {any} val - 基本类型数据或者引用类型数据
 * @return {string} - 可能返回的结果有，均为小写字符串
 * number、boolean、string、null、undefined、array、object、function等
 */
export function getType(val) {
    //判断数据是 null 和 undefined 的情况
    if (val == null) {
        return val + "";
    }
    return typeof (val) === "object" ?
        Object.prototype.toString.call(val).slice(8, -1).toLowerCase() :
        typeof (val);
}

/*
 * 验证是否为数字
 */
export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/*
 * 是否为数组
 */
export function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
}

/*
 * 是否空数组
 */
export function isArrayEmpty(val) {
    if (val && val instanceof Array && val.length > 0) {
        return false;
    } else {
        return true;
    }
}

/*
 * 获取url参数字符串
 * 没有返回null
 */
export function getQueryString(name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

// 数字四舍五入（保留n位小数） val要保留的数据，places保留位数，type返回类型（1数字，2字符串）
export function Decimals(value, n) {
    var f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n)
    var s = f.toString();
    var rs = s.indexOf('.')
    if (rs < 0) {
        s += '.'
    }
    for (var i = s.length - s.indexOf('.'); i <= n; i++) {
        s += "0"
    }
    return s
}

/*
 *数字每千位加逗号
 * 
 */
export function formatNum(strNum) {
    // 首先，检查 strNum 是否未定义或为 null，并返回空字符串或某个默认值。
    if (strNum === undefined || strNum === null) {
        return '';
    }

    // 将非字符串输入转换为字符串。
    strNum = String(strNum);

    // 检查字符串是否不是数字或者是否已格式化。
    if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
        return strNum;
    }

    var a = RegExp.$1, //符号部分（+或-）
        b = RegExp.$2, // 整数部分
        c = RegExp.$3 || ''; // 小数部分，确保如果不存在则默认为空字符串。

    var re = new RegExp();
    re.compile('(\\d)(\\d{3})(,|$)');
    while (re.test(b)) {
        b = b.replace(re, '$1,$2$3');
    }
    return a + b + c;
}


// 格式化时间yyyy-MM-dd
export function formatDate(timestamp, formater) {
    const date = new Date()
    date.setTime(parseInt(timestamp))
    //  传入formater形式 yyyy-MM-dd yyyy-MM-dd hh:mm
    formater = (formater != null) ? formater : 'yyyy-MM-dd'
    const Format = function (fmt) {
        var o = {
            'M+': date.getMonth() + 1, // 月
            'd+': date.getDate(), // 日
            'h+': date.getHours(), // 小时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
            'S': date.getMilliseconds() // 毫秒
        }

        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1)
                    ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
            }
        }
        return fmt
    }
    return Format(formater)
}

//将当前时刻时间new Date()转换为YYYY-mm-dd、YYYY-mm-dd HH:MM:SS字符串形式
export function dateTypeFormat(fmt, date) {
    let ret
    const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        }
    }
    return fmt
}


//数据显示设置不同颜色
export function setNumberColor(num) {
    let a = num;
    if (a < 0) {
        return 'rgba(247, 82, 95, 1)';
    }
    if (a > 0) {
        return 'rgba(0, 174, 147, 1)';
    }

    if ((a = 0)) {
        return '#fffff';
    }
}


//英文的日期时间格式
export function getCurrentDate(time) {
    var date = new Date(time);
    var monthArray = new Array
        ("January", "February", "March", "April", "May", "June", "July", "August",
            "September", "October", "November", "December");
    var weekArray = new Array("Sunday", "Monday", "Tuesday",
        "Wednesday", "Thursday", "Friday", "Saturday");
    let month = date.getMonth();
    let day = date.getDate();
    if (day.toString().length == 1) {
        day = "0" + day.toString();
    }
    let newDate = monthArray[month] + " " + day + ", " +
        date.getFullYear() + "  " + weekArray[date.getDay()];
    return newDate;
}

//英文的日期时间格式
export function weekDay(time) {
    var date = new Date(time);
    var weekArray = new Array("Sunday", "Monday", "Tuesday",
        "Wednesday", "Thursday", "Friday", "Saturday");
    let newDate = weekArray[date.getDay()]
    return newDate;
}




