export default [
  // 加一个路由 就得在这个里面配置一下
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: '首页',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: '资金费率',
        route: 'FundingRate',
        icon: 'DollarSignIcon',
      },
      {
        title: '多空比',
        icon: 'PercentIcon',
        route: 'duokongbi',
        children: [
          {
            title: 'Binance',
            icon: 'FileIcon',
            children: [
              {
                title: '大户账户数多空比',
                route: 'Accounts',
                icon: 'TagIcon',
              },
              {
                title: '大户持仓量多空比',
                route: 'Positions',
                icon: 'FileIcon',
              },
              {
                title: '账户数多空比',
                route: 'LongShortRatio',
                icon: 'FileIcon',
              },
            ]
          },
          {
            title: 'Bybit',
            icon: 'FileIcon',
            children: [
              {
                title: '账户数多空比',
                route: 'BybitLongShortRatio',
                icon: 'FileIcon',
              },
            ]
          },
        ],
      },
      {
        title: '期权',
        icon: 'PieChartIcon',
        children: [
          {
            title: 'Deribit期权',
            route: 'options',
            icon: 'FileIcon',
          },
          {
            title: '大宗交易',
            icon: 'FileIcon',
            route: 'BlockTransactions',
          },
        ],
      },

    ],
  },
]
