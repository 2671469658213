import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/home', // Redirect from '/' to '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/dashboard/Home.vue'),
      meta: {
        pageTitle: '首页',
        breadcrumb: [
          {
            text: '首页',
            active: true,
          },
        ],
      },
    },
    {
      path: "/positionData",
      name: "positionData",
      component: () => import('@/views/Option/option-data/positionData.vue'),
      meta: {
        pageTitle: "期权Deribit",
        breadcrumb: [
          {
            text: "持仓数据"
          }
        ]
      }
    },
    {
      path: "/compositeNumber",
      name: "compositeNumber",
      component: () => import('@/views/Option/option-data/compositeNumber.vue'),
      meta: {
        pageTitle: "期权Deribit",
        breadcrumb: [
          {
            text: "成交数据"
          }
        ]
      }
    },
    {
      path: "/waveRate",
      name: "waveRate",
      component: () => import('@/views/Option/option-data/waveRate.vue'),
      meta: {
        pageTitle: "期权Deribit",
        breadcrumb: [
          {
            text: "波动率"
          }
        ]
      }
    },
    {
      path: "/otherNumbers",
      name: "otherNumbers",
      component: () => import('@/views/Option/option-data/otherNumbers.vue'),
      meta: {
        pageTitle: "期权Deribit",
        breadcrumb: [
          {
            text: "其他数据"
          }
        ]
      }
    },

    {
      path: "/bybitCompositeNumber",
      name: "bybitCompositeNumber",
      component: () => import('@/views/Option/Bybit/bybitCompositeNumber.vue'),
      meta: {
        pageTitle: "期权Bybit",
        breadcrumb: [
          {
            text: "成交数据"
          }
        ]
      }
    },
    {
      path: "/bybitWaveRate",
      name: "bybitWaveRate",
      component: () => import('@/views/Option/Bybit/bybitWaveRate.vue'),
      meta: {
        pageTitle: "期权Bybit",
        breadcrumb: [
          {
            text: "波动率"
          }
        ]
      }
    },
    {
      path: "/bybitOtherNumbers",
      name: "bybitOtherNumbers",
      component: () => import('@/views/Option/Bybit/bybitOtherNumbers.vue'),
      meta: {
        pageTitle: "期权Bybit",
        breadcrumb: [
          {
            text: "其他数据"
          }
        ]
      }
    },

    {
      path: '/FundingRate',
      name: 'FundingRate',
      component: () => import('@/views/FundingRate.vue'),
      meta: {
        pageTitle: '资金费率',
        breadcrumb: [
          {
            text: '资金费率',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Accounts',
      name: 'Accounts',
      component: () => import('@/views/short_ratio/Binance/Accounts.vue'),
      meta: {
        pageTitle: 'Binance',
        breadcrumb: [
          {
            text: '大户账户数多空比例',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/Positions',
      name: 'Positions',
      component: () => import('@/views/short_ratio/Binance/Positions.vue'),
      meta: {
        pageTitle: 'Binance',
        breadcrumb: [
          {
            text: '大户持仓量比例',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/LongShortRatio',
      name: 'LongShortRatio',
      component: () => import('@/views/short_ratio/Binance/LongShortRatio.vue'),
      meta: {
        pageTitle: 'Binance',
        breadcrumb: [
          {
            text: '账户数多空比例',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/BybitLongShortRatio',
      name: 'BybitLongShortRatio',
      component: () => import('@/views/short_ratio/Bybit/BybitLongShortRatio.vue'),
      meta: {
        pageTitle: 'Bybit',
        breadcrumb: [
          {
            text: '账户数多空比例',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/options',
      name: 'options',
      component: () => import('@/views/Option/options.vue'),
      meta: {
        pageTitle: '期权',
        breadcrumb: [
          {
            text: 'Deribit期权',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/deribitOptionUnderlying',
      name: 'deribitOptionUnderlying',
      component: () => import('@/views/Option/deribitOptionUnderlying.vue'),
      meta: {
        pageTitle: '期权',
        breadcrumb: [
          {
            text: 'Deribit期权标的',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/dashboard/Home.vue'),
      meta: {
        pageTitle: '首页',
        breadcrumb: [
          {
            text: '首页',
            active: true,
          },
        ],
      },
    },
    {
      path: '/btcEtf',
      name: 'btcEtf',
      component: () => import('@/views/BtcEtf/BtcEtf.vue'),
      meta: {
        pageTitle: 'BTC-ETF',
        breadcrumb: [
          {
            text: 'BTC-ETF',
            // active: true,
          },
        ],
      },
    },
    // {
    //   path: '/positionData',
    //   name: 'positionData',
    //   component: () => import('@/views/Option/option-data/positionData.vue'),
    //   meta: {
    //     pageTitle: 'Deribit期权持仓数据',
    //     breadcrumb: [
    //       {
    //         text: 'Deribit期权持仓数据',
    //         // active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   // path: "/deribitOption?type=deribit",
    //   path: "/positionData",
    //   name: 'positionData',
    //   component: () => import('@/views/Option/option-data/positionData.vue'),

    //   meta: {
    //     pageTitle: '期权',
    //     breadcrumb: [
    //       {
    //         text: 'Deribit期权持仓数据',
    //         // active: true,
    //       },
    //     ],
    //   },
    // },
    {
      // path: "/deribitOption?type=deribit",
      path: "/deribitOption",
      name: 'deribitOption',
      component: () => import('@/views/Option/deribitOption.vue'),
      meta: {
        pageTitle: '期权',
        breadcrumb: [
          {
            text: 'Deribit',
            // active: true,
          },
        ],
      },
    },
    {
      path: "/bybitOption",
      name: 'bybitOption',
      component: () => import('@/views/Option/bybitOption.vue'),
      meta: {
        pageTitle: '期权',
        breadcrumb: [
          {
            text: 'Bybit',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/deribitOptionsPosition',
      name: 'deribitOptionsPosition',
      component: () => import('@/views/Option/deribitOptionsPosition.vue'),
      meta: {
        pageTitle: '期权',
        breadcrumb: [
          {
            text: 'Deribit期权持仓量',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/AllBrowse',
      name: 'AllBrowse',
      component: () => import('@/views/BtcEtf/AllBrowse.vue'),
      meta: {
        pageTitle: 'BTC-ETF',
        breadcrumb: [
          {
            text: '总览',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/Bito',
      name: 'Bito',
      component: () => import('@/views/BtcEtf/Bito.vue'),
      meta: {
        pageTitle: 'BTC-ETF',
        breadcrumb: [
          {
            text: 'BITO',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/Btf',
      name: 'Btf',
      component: () => import('@/views/BtcEtf/Btf.vue'),
      meta: {
        pageTitle: 'BTC-ETF',
        breadcrumb: [
          {
            text: 'BTF',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/Purpose',
      name: 'Purpose',
      component: () => import('@/views/BtcEtf/Purpose.vue'),
      meta: {
        pageTitle: 'BTC-ETF',
        breadcrumb: [
          {
            text: 'Purpose',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/BybitBlockTransactions',
      name: 'BybitBlockTransactions',
      component: () => import('@/views/BlockTransactions/BybitBlockTransactions.vue'),
      meta: {
        pageTitle: '大宗交易',
        breadcrumb: [
          {
            text: 'Bybit期权',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/DeribitBlockTransactions',
      name: 'DeribitBlockTransactions',
      component: () => import('@/views/BlockTransactions/DeribitBlockTransactions.vue'),
      meta: {
        pageTitle: '大宗交易',
        breadcrumb: [
          {
            text: 'Deribit期权',
            // active: true,
          },
        ],
      },
    },
    {
      path: '/inHistoricalData',
      name: 'inHistoricalData',
      component: () => import('@/views/BlockTransactions/inHistoricalData.vue'),
      meta: {
        pageTitle: 'Deribit 标的聚合信息',
        breadcrumb: [
          // {
          //   text: '1111',
          //   // active: true,
          // },
        ],
      },
    },
    {
      path: '/TVChartContainer',
      name: 'TVChartContainer',
      component: () => import('@/views/Tv/TVChartContainer.vue'),
      meta: {
        pageTitle: 'K线',
        breadcrumb: [
          {
            text: 'K线',
            // active: true,
          },
        ],
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // 移除初始加载
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
