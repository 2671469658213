<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header navbar-logo-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="appLogo" alt="logo" />
              </span>
              <h2 class="brand-text">{{ appName }}</h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle" style="display: none">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <!-- <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar> -->
    <!-- /main menu content-->
    <el-menu
      :default-active="this.$route.path"
      class="my-menu"
      @open="handleOpen"
      @close="handleClose"
      background-color="transparent"
      text-color="rgba(209, 212, 220, 0.8)"
      active-text-color="#000000"
      :default-openeds="openedSubmenus"
      router
    >
      <el-menu-item
        index="/home"
        :class="{ active: $route.path.startsWith('/home') }"
      >
        <i
          :style="{
            color: $route.path.startsWith('/home')
              ? 'rgba(237, 189, 136, 1)'
              : '',
          }"
        >
          <img
            :src="
              $route.path.startsWith('/home')
                ? require('@/assets/images/menu/home-y.svg')
                : require('@/assets/images/menu/home.svg')
            "
            alt="Home Icon"
        /></i>
        <span
          slot="title"
          :style="{
            color: $route.path.startsWith('/home')
              ? 'rgba(237, 189, 136, 1)'
              : '',
          }"
          >首页</span
        >
      </el-menu-item>
      <el-submenu index="option">
        <template slot="title">
          <i
            :style="{
              color:
                $route.path.startsWith('/positionData') ||
                $route.path.startsWith('/compositeNumber') ||
                $route.path.startsWith('/waveRate') ||
                $route.path.startsWith('/otherNumbers') ||
                $route.path.startsWith('/bybitCompositeNumber') ||
                $route.path.startsWith('/bybitWaveRate') ||
                $route.path.startsWith('/bybitOtherNumbers')
                  ? 'rgba(237, 189, 136, 1)'
                  : '',
            }"
          >
            <img
              :src="
                $route.path.startsWith('/positionData') ||
                $route.path.startsWith('/compositeNumber') ||
                $route.path.startsWith('/waveRate') ||
                $route.path.startsWith('/otherNumbers') ||
                $route.path.startsWith('/bybitCompositeNumber') ||
                $route.path.startsWith('/bybitWaveRate') ||
                $route.path.startsWith('/bybitOtherNumbers')
                  ? require('@/assets/images/menu/option-y.svg')
                  : require('@/assets/images/menu/option.svg')
              "
              alt="Option Icon"
          /></i>
          <span
            :style="{
              color:
                $route.path.startsWith('/positionData') ||
                $route.path.startsWith('/compositeNumber') ||
                $route.path.startsWith('/waveRate') ||
                $route.path.startsWith('/otherNumbers') ||
                $route.path.startsWith('/bybitCompositeNumber') ||
                $route.path.startsWith('/bybitWaveRate') ||
                $route.path.startsWith('/bybitOtherNumbers')
                  ? 'rgba(237, 189, 136, 1)'
                  : '',
            }"
            >期权</span
          >
        </template>
        <el-submenu index="option-deribit">
          <template slot="title"
            ><span
              :style="{
                color:
                  $route.path.startsWith('/positionData') ||
                  $route.path.startsWith('/compositeNumber') ||
                  $route.path.startsWith('/waveRate') ||
                  $route.path.startsWith('/otherNumbers')
                    ? 'rgba(237, 189, 136, 1)'
                    : '',
              }"
              >Deribit</span
            >
          </template>
          <el-menu-item index="/positionData">持仓数据</el-menu-item>
          <el-menu-item index="/compositeNumber">成交数据</el-menu-item>
          <el-menu-item index="/waveRate">波动率</el-menu-item>
          <el-menu-item index="/otherNumbers">其他数据</el-menu-item>
        </el-submenu>
        <el-submenu index="option-bybit">
          <template slot="title"
            ><span
              :style="{
                color:
                  $route.path.startsWith('/bybitCompositeNumber') ||
                  $route.path.startsWith('/bybitWaveRate') ||
                  $route.path.startsWith('/bybitOtherNumbers')
                    ? 'rgba(237, 189, 136, 1)'
                    : '',
              }"
              >Bybit</span
            ></template
          >
          <el-menu-item index="/bybitCompositeNumber">成交数据</el-menu-item>
          <el-menu-item index="/bybitWaveRate">波动率</el-menu-item>
          <el-menu-item index="/bybitOtherNumbers">其他数据</el-menu-item>
        </el-submenu>
      </el-submenu>
      <el-submenu index="block-transactions">
        <template slot="title">
          <i
            :style="{
              color:
                $route.path.startsWith('/DeribitBlockTransactions') ||
                $route.path.startsWith('/BybitBlockTransactions')
                  ? 'rgba(237, 189, 136, 1)'
                  : '',
            }"
          >
            <img
              :src="
                $route.path.startsWith('/DeribitBlockTransactions') ||
                $route.path.startsWith('/BybitBlockTransactions')
                  ? require('@/assets/images/menu/block-y.svg')
                  : require('@/assets/images/menu/block.svg')
              "
              alt="Block Icon"
          /></i>
          <span
            :style="{
              color:
                $route.path.startsWith('/DeribitBlockTransactions') ||
                $route.path.startsWith('/BybitBlockTransactions')
                  ? 'rgba(237, 189, 136, 1)'
                  : '',
            }"
            >大宗交易</span
          >
        </template>
        <el-menu-item index="/DeribitBlockTransactions"
          >Deribit期权</el-menu-item
        >
        <el-menu-item index="/BybitBlockTransactions">Bybit期权</el-menu-item>
      </el-submenu>
      <el-menu-item
        index="/btcEtf"
        :class="{ active: $route.path.startsWith('/btcEtf') }"
      >
        <i
          :style="{
            color: $route.path.startsWith('/btcEtf')
              ? 'rgba(237, 189, 136, 1)'
              : '',
          }"
        >
          <img
            :src="
              $route.path.startsWith('/btcEtf')
                ? require('@/assets/images/menu/btc-etf-y.svg')
                : require('@/assets/images/menu/btc-etf.svg')
            "
            alt="Home Icon"
        /></i>
        <span
          slot="title"
          :style="{
            color: $route.path.startsWith('/btcEtf')
              ? 'rgba(237, 189, 136, 1)'
              : '',
          }"
          >BTC-ETF</span
        >
      </el-menu-item>

      <!-- <el-menu-item index="/TVChartContainer">TV</el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref, watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  data() {
    return {
      openedSubmenus: [
        "option",
        "option-deribit",
        "option-bybit",
        "block-transactions",
      ],
    };
  },

  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      // isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin, isVerticalMenuCollapsed } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage, appLogoSmallImage } = $themeConfig.app;

    const appLogo = ref(appLogoImage);

    watch(isMouseHovered, (va) => {
      if (va && isVerticalMenuCollapsed.value) {
        appLogo.value = appLogoImage;
      } else if (!isVerticalMenuCollapsed.value) {
        appLogo.value = appLogoImage;
      } else {
        appLogo.value = appLogoSmallImage;
      }
    });

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogo,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.navigation {
  background: transparent !important;
  .menu-content {
    background: transparent !important;
  }
}
.active img {
  fill: rgba(237, 189, 136, 1); /* 修改图标颜色 */
}
.my-menu {
  border-right: none !important;

  // /* 选中状态样式 */
  // .el-menu-item.is-active {
  //   color: #000000; /* 选中字体颜色 */
  //   background-color: rgba(221, 193, 163, 1) !important; /* 选中背景颜色 */
  //   span {
  //     color: #000000 !important;
  //   }
  // }
  .el-menu-item {
    padding-left: 42px !important;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #d1d4dc;
    line-height: 50px;
    transition: padding-left 0.3s ease; /* 过渡效果，0.3秒的持续时间，ease是缓动函数，可以根据需要调整 */
    display: flex;
    i {
      margin-right: 18px;
    }
    span {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(209, 212, 220, 0.8);
      line-height: 56px;
    }
  }
  .el-submenu__title:hover {
    background: #3d3f47 !important;
  }
  /* 悬停状态样式 */
  .el-menu-item:not(.is-active):hover {
    background: #3d3f47 !important;
  }
  .el-menu-item.is-active:hover {
    background: transparent !important;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 48px !important;
  }
  .el-submenu {
    .el-submenu__title {
      padding-left: 42px !important;
      i {
        margin-right: 18px;
      }
      span {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(209, 212, 220, 0.8);
        line-height: 56px;
      }
    }
    .el-menu {
      .el-menu-item {
        padding: 0 45px 0 100px !important;
      }
      .el-menu-item.is-active {
        background-color: rgba(221, 193, 163, 1) !important; /* 选中背景颜色 */
        span {
          color: #000000 !important;
          font-weight: 400 !important;
          font-size: 12px;
          font-family: MicrosoftYaHei;
        }
      }
      .el-menu-item:not(.is-active):hover {
        background: #3d3f47 !important;
        padding-left: 93px !important;
      }
      .el-submenu {
        .el-submenu__title {
          padding-left: 88px !important;
        }
        /* 悬停状态样式 */
        .el-menu-item.is-active {
          color: #000000; /* 选中字体颜色 */
          font-weight: 400 !important;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          background-color: rgba(
            221,
            193,
            163,
            1
          ) !important; /* 选中背景颜色 */
        }
        .el-menu-item:not(.is-active):hover {
          background: #3d3f47 !important;
          padding-left: 93px !important;
        }
      }
    }
  }
}
</style>
